/* eslint-disable import/no-unused-modules */
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from '../components/Helmet';
import { PolicyLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';

export const Head = withI18n(() => {
  return <Helmet pageName="cookiePolicy" />;
});

const CookiePolicy = () => (
  <PolicyLayout
    title="cookie-policy.title"
    intro="cookie-policy.introduction"
    content={cookiePolicyContents}
  />
);

const cookiePolicyContents = [
  {
    title: 'cookie-policy.definition.title',
    content: 'cookie-policy.definition.content',
  },
  {
    title: 'cookie-policy.types.title',
    content: 'cookie-policy.types.content',
  },
  {
    title: 'cookie-policy.contact.title',
    content: 'cookie-policy.contact.content',
    email: {
      textBefore: 'cookie-policy.email',
      emailID: 'info@novalabs-qa.com',
    },
  },
];

export default CookiePolicy;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
